import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';
import { Col, Row } from 'antd';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const WhoGallery = ({ photos }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	photos = photos.map(i => ({
		src: i.img.tmb.url,
		url: i.img.url,
		width: i.img.dimensions.width,
		height: i.img.dimensions.height
	}));

	return (
		<>
			<Gallery photos={photos} onClick={openLightbox} />

			{viewerIsOpen ? (
				<Lightbox
					mainSrc={photos[currentImage].url}
					nextSrc={photos[(currentImage + 1) % photos.length].url}
					prevSrc={photos[(currentImage + photos.length - 1) % photos.length].url}
					onCloseRequest={() => setViewerIsOpen(false)}
					onMovePrevRequest={() => setCurrentImage((currentImage + photos.length - 1) % photos.length)}
					onMoveNextRequest={() => setCurrentImage((currentImage + 1) % photos.length)}
				/>
			) : null}
		</>
	);
};

const WhoPage = () => {
	const [ sections, setSections ] = useState([]);
	const [ images, setImages ] = useState([]);
	const [ team, setTeam ] = useState(null);

	const data = useStaticQuery(graphql`
	    query {
	      imageWho: file(relativePath: { eq: "who.png" }) {
	        childImageSharp {
	          fluid(maxWidth: 1920) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X4R3GhEAACcAF4iN', { lang : 'fr-ch' })
		).then(response => {
			setSections(response.data.section);
			setImages(response.data.images);
			setTeam(response.data.body[0]);
		});
	}, []);

	return (
		<AppLayout>
			<SEO title="Qui ?" />

			<div className="hero-image">
				<Img fluid={data.imageWho.childImageSharp.fluid} />
			</div>

			<div>
				{sections.map((section, key) => (
					<div key={key} className="page-section">
						<div className="section-title">
							<h2>
								{section.titre.length ? section.titre[0].text : ''}
								{section.legume_image.url ? (
									<img src={section.legume_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								) : null}
							</h2>
							<div className="section-title-line" />
						</div>

						<div className="section-content">
							{section.paragraph.length ? (
								<div className="section-content-text">
									{section.sous_titre.length ? <h3>{section.sous_titre[0].text}</h3> : null}
									<div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(section.paragraph) }} />
								</div>
							) : null}

							{section.content_image.url ? (
								<div className="section-content-image">
									<img src={section.content_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								</div>
							) : null}
						</div>

						{key === 0 && (
							<div className="section-content">
								<WhoGallery photos={images} />
							</div>
						)}
					</div>
				))}

				{team !== null ? (
					<div className="page-section">
						<div className="section-title">
							<h2>
								{team.primary.titre.length ? team.primary.titre[0].text : ''}
								{team.primary.image_legume.url ? (
									<img src={team.primary.image_legume.url} alt={team.primary.titre.length ? team.primary.titre[0].text : ''} />
								) : null}
							</h2>
							<div className="section-title-line" />
						</div>
						<div className="section-content">
							<Row gutter={32} justify="space-around" align="middle" style={{ width: '100%' }}>
								{team.items.map((member, key) => (
									<Col key={key} xs={24} sm={12} md={8} lg={6} xl={4}>
										<div className="member-infos">
											<img src={member.portrait.url} alt={member.first_and_lastname[0].text} />
											<h4>{member.first_and_lastname[0].text}</h4>
											{member.position.length ? <p>{member.position[0].text}</p> : null}
										</div>
									</Col>
								))}
							</Row>
						</div>
					</div>
				) : null}
			</div>
		</AppLayout>
	);
};

export default withTrans(WhoPage);
